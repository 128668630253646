import React from 'react';
import Layout from '../components/Layout';
import { StaticQuery, graphql } from 'gatsby';
// import { Link } from 'gatsby';
import { navigate } from 'gatsby';
// import { Link } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import SEO from 'components/SEO/SEO';
// import Header from 'components/Headers/Header';
import logger from 'utils/logger';
import querystring from 'querystring';
import { regions } from 'country-data';
import Fuse from "fuse.js";

import { Row, Col, NavItem } from 'reactstrap';
import { Fluid, Offset } from 'components/Containers';
import { CardContact, CardDistributor } from 'components/Cards/Cards';
import SimpleHeader from 'components/Headers/SimpleHeader';
import { SearchSchema } from 'api/schemas/_Global';

import { Sticky } from 'react-sticky';
import matchSorter from 'match-sorter';

import LoadingView from 'components/Loaders/LoadingView';
import NavCategories from 'components/Navigation/NavCategories';
import AutoForm from 'uniforms-unstyled/AutoForm';
import TextField from 'uniforms-unstyled/TextField';

class Template extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      filters: {}
    };
  }
  handleChange (key, value) {
    logger.info('key', key, 'value', value);
    this.setState({
      filters: { [`search`]: value }
    });
  }
  handleQueryString (params, scroll = 0) {
    logger.group(__filename, '>', `handleQueryString (${JSON.stringify(params)})`);
    logger.debug('handleQueryString this:', this);
    if (typeof window !== 'undefined') {
      logger.verbose('handleQueryString params:', params);
      const pageQuery = querystring.parse(window.location.search.substring(1));
      logger.verbose('handleQueryString pageQuery:', pageQuery);

      Object.keys(params).forEach((key) => {
        logger.verbose(key, params[key]);
        pageQuery[key] = params[key];
      });

      logger.verbose('handleQueryString pageQuery update:', pageQuery);

      const queryUpdate = `${window.location.pathname.substring(0)}?${querystring.stringify(pageQuery)}`;
      logger.verbose('handleQueryString queryUpdate:', queryUpdate);

      navigate(queryUpdate);
      window.scrollTo({
        top: scroll,
        behavior: 'smooth'
      });
    }
    logger.groupEnd();
  }
  render () {
    logger.template(__filename, '>', 'render()');
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const data = get(this, 'props.data');
    logger.gql(data);
    if (
      !data ||
      !data.contentfulPage ||
      !data.allContentfulCategory ||
      !data.allContentfulContacts ||
      !data.allContentfulDistributors
    ) { return <LoadingView />; }
    // const parsed = querystring.parse(location.search);
    // logger.debug(`querystring`, parsed);
    const gutter = 8;
    const pageData = data.contentfulPage;
    const categoriesData = data.contentfulMenu.fields.links;
    const contactsData = data.allContentfulContacts.edges;
    let distributorsData = data.allContentfulDistributors.edges;
    logger.debug(`categoriesData`, categoriesData);

    const filter = this.state.filters.search;
    logger.debug(`regions`, regions);

    logger.debug(`distributorsData`, distributorsData);
    let showDirect = true;
    let showDistributors = true;

    // QueryString Filters
    let pageQuery = {};
    let filterRegion = [];
    if (typeof window !== 'undefined') {
      logger.group(`QueryString Filters`);
      pageQuery = querystring.parse(window.location.search.substring(1));

      Object.keys(pageQuery).forEach((key) => {
        logger.verbose(key);
        if (pageQuery[key] === 'all' || pageQuery[key] === 'ALL' || pageQuery[key] === 'All') {
          pageQuery[key] = null;
        }
      });

      logger.info('QueryString Filters pageQuery:', pageQuery);
      // Filter by series
      if (pageQuery.category) {
        if (
          pageQuery.category !== 'all' || pageQuery.category !== 'ALL' || pageQuery.category !== 'All'
        ) {
          showDirect = pageQuery.category === `direct-sales`;
          showDistributors = pageQuery.category === `distributors`;
        }
      }

      // Filter by region
      if (pageQuery.region) {
        if (
          pageQuery.region !== 'all' || pageQuery.region !== 'ALL' || pageQuery.region !== 'All'
        ) {
          if (pageQuery.region === 'emea') {
            const filterCountries = filterRegion.concat(
              regions.eastAfrica.countries,
              regions.southernAfrica.countries,
              regions.centralAfrica.countries,
              regions.westAfrica.countries,
              regions.northAfrica.countries,

              regions.easternEurope.countries,
              regions.northernEurope.countries,
              regions.southernEurope.countries,
              regions.westernEurope.countries
            );
            filterRegion = filterCountries;
            logger.debug(`filterCountries`, filterCountries);
          }
          if (pageQuery.region === 'apac') {
            const filterCountries = filterRegion.concat(
              regions.centralAsia.countries,
              regions.eastAsia.countries,
              regions.southeastAsia.countries,
              regions.southernAsia.countries,
              regions.westernAsia.countries,
              regions.australia.countries,
              regions.melanesia.countries,
              regions.micronesia.countries,
              regions.polynesia.countries
            );
            filterRegion = filterCountries;
            logger.debug(`filterCountries`, filterCountries);
          }
          if (pageQuery.region === 'latam') {
            const filterCountries = filterRegion.concat(
              regions.caribbean.countries,
              regions.centralAmerica.countries,
              regions.northernAmerica.countries,
              regions.southAmerica.countries,
              regions.antartica.countries
            );
            filterRegion = filterCountries;
            logger.debug(`filterCountries`, filterCountries);
          }
        }
      } else {
        for (const region in regions) {
          // arr.concat(regions[region].countries);
          const filterCountries = filterRegion.concat(regions[region].countries);
          filterRegion = filterCountries;
          logger.debug(`filterCountries`, filterCountries);
          logger.debug(`filterRegion`, filterRegion);
        }
      }
      logger.groupEnd();
    }
    logger.debug(`filterRegion`, filterRegion);

    // const fuse = new Fuse(data.allContentfulDistributors.edges, {
    //   keys: [
    //     'node.serviceCountries',
    //     'node.address.country'
    //   ]
    // });
    // distributorsData = fuse.search(filterRegion);

    distributorsData = matchSorter(
      distributorsData,
      filter, {
        keys: [
          item => item.node.serviceCountries,
          item => item.node.address.address.country,
          item => item.node.company
        ],
        threshold: matchSorter.rankings.CONTAINS
      }
    );
    logger.debug(`distributorsData`, distributorsData);

    logger.templateEnd();

    return (
      <Layout>
        <Fluid>
          <SEO
            title={pageData.title}
            pathname={`/buy`}
          />
          <SimpleHeader title={pageData.title} />
          <Sticky topOffset={49 + 144}>
            {({ style }) => (
              <header className={`z-300`} style={style}>
                <NavCategories categories={categoriesData} allButton={`All`} className={`bg-indigo-700`}>
                  <NavItem className={`${!showDirect ? 'active' : `hide`}`}>
                    <button
                      onClick={(e) => { this.handleQueryString({ region: 'all' }); }}
                      alt={`All Product Lines`}
                      className={`btn btn-link ptb-0 pl-0 inline tt-none fs-11em ls-10em color-white-hover${!pageQuery.region || pageQuery.region === 'all' ? ` color-indigo-100` : ` active color-indigo-300`}`}
                    >
                      ALL
                    </button>
                  </NavItem>
                  <NavItem className={`${!showDirect ? 'active' : `hide`}`}>
                    <button
                      onClick={(e) => { this.handleQueryString({ region: 'emea' }); }}
                      alt={`EMEA`}
                      className={`btn btn-link ptb-0 pl-0 inline tt-none fs-11em ls-10em color-white-hover${!pageQuery.region || pageQuery.region === 'all' ? ` color-indigo-100` : ` active color-indigo-300`}`}
                    >
                      EMEA
                    </button>
                  </NavItem>
                  <NavItem className={`${!showDirect ? 'active' : `hide`}`}>
                    <button
                      onClick={(e) => { this.handleQueryString({ region: 'apac' }); }}
                      alt={`APAC`}
                      className={`btn btn-link ptb-0 pl-0 inline tt-none fs-11em ls-10em color-white-hover${!pageQuery.region || pageQuery.region === 'all' ? ` color-indigo-100` : ` active color-indigo-300`}`}
                    >
                      APAC
                    </button>
                  </NavItem>
                  <NavItem className={`${!showDirect ? 'active' : `hide`}`}>
                    <button
                      onClick={(e) => { this.handleQueryString({ region: 'latam' }); }}
                      alt={`Americas`}
                      className={`btn btn-link ptb-0 pl-0 inline tt-none fs-11em ls-10em color-white-hover${!pageQuery.region || pageQuery.region === 'all' ? ` color-indigo-100` : ` active color-indigo-300`}`}
                    >
                      Americas
                    </button>
                  </NavItem>
                  <NavItem className={`${!showDirect ? 'active' : `hide`}`}>
                    <i className="icon icon-Magnifi-Glass2 pos-relative top-4px" style={{ left: `10px`}} />
                  </NavItem>
                  <NavItem className={`${!showDirect ? 'active' : `hide`}`}>
                    <AutoForm
                      schema={SearchSchema}
                      showInlineError
                      validate="onChangeAfterSubmit"
                      onChange={(key, value) => {
                        this.handleChange(key, value);
                      }}
                    >
                      <div className={`inline-block mr-5em pos-relative`} style={{ left: `-1em`, top: `-1px` }}>
                        <TextField
                          name="search"
                          label={``}
                          placeholder={`Search Resellers`}
                          className={`navbarSearch ${filter && (filter.search && `focus`)}`}
                          errorMessage={`Please enter a valid email`}
                        />
                      </div>
                    </AutoForm>
                  </NavItem>
                </NavCategories>
              </header>
            )}
          </Sticky>
          <section className={`ptb-30em ${showDirect ? 'active' : `hide`}`}>
            <Offset>
              <Row>
                <Col xs={12}>
                  <h1 className={`fs-16em ls--20em pb-10em mb-15em bb-1`}>Direct Sales Contact</h1>
                </Col>
                { contactsData && contactsData.map((doc) => {
                  const contact = doc.node;
                  return (
                    <Col key={contact.slug} md={4} lg={3} xl={50} className={`prl-${gutter}px pb-${gutter}px`}>
                      <CardContact {...contact} />
                    </Col>
                  );
                })}
              </Row>
            </Offset>
          </section>
          <section className={`ptb-30em ${showDistributors ? 'active' : `hide`}`}>
            <Offset>
              <Row>
                <Col xs={12}>
                  <h1 className={`fs-16em ls--20em pb-10em mb-15em bb-1`}>Authorized Resellers</h1>
                </Col>
                { distributorsData && distributorsData.map((doc) => {
                  const contact = doc.node;
                  let filterContact;
                  if (filterRegion) {
                    contact.serviceCountries.forEach((country) => {
                      if (!filterContact) {
                        filterRegion.forEach((region) => {
                          if (country === region) {
                            filterContact = true;
                          }
                        });
                      }
                      // logger.debug(`contact.serviceCountries`, filterContact, country);
                    });
                  } else {
                    // filterContact += 1;
                  }
                  // logger.debug(`contact`, `filterContact`, filterContact, contact.company, contact.address.country);
                  return (
                    (
                      !filterRegion ||
                      filterContact
                    ) && (
                      <Col key={contact.slug} md={4} lg={3} xl={50} className={`prl-${gutter}px pb-${gutter}px`}>
                        <CardDistributor {...contact} />
                      </Col>
                    )
                  );
                })}
              </Row>
            </Offset>
          </section>
        </Fluid>
      </Layout>
    );
  }
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        titleSeparator
      }
    }
    contentfulPage(slug: {eq: $slug}) {
      title
    }
    allContentfulContacts(limit: 10, sort: {fields: [sort], order: DESC}, filter: {category: {elemMatch: {slug: {eq: "direct-sales"}}}}) {
      edges {
        node {
          id
          ... Contact
        }
      }
    }
    allContentfulDistributors( limit: 500 sort: { fields: fields___country, order: ASC} ){
      edges {
        node {
          id
          ... Distributor
        }
      }
    }
    contentfulMenu(location: {eq: "Where to Buy"}){
      name
      location
      fields{
        links{
          id
          key
          name
          slug
          contentTypes
        }
      }
    }
    allContentfulCategory( filter: { contentTypes: { in: ["sales-contact"] } } ){
      edges{
        node {
          id
          ... Category
        }
      }
    }
  }
`;

export default Template;

// export default ({ children }) => (
//   <StaticQuery
//     query={query}
//     render={data => (
//       <>
//         <Helmet title={`${`Where to Buy`}`} />
//         <Template data={data}>
//           {children}
//         </Template>
//       </>
//     )}
//   />
// );
